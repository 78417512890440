/* eslint-disable import/no-anonymous-default-export */
export default {
    app: {
        url: 'www.bulgari.com'
    },
    common: {
        close: 'Close'
    },
    menu:{
        digital_prewarranties: 'Pre Activations',
        areas: 'Area',
        profiles: 'Authorization profiles',
        manual: 'Watch manuals',
        help: 'Help',
        info: 'Info',
        massive_activations: 'Massive activations',
        partnerinventories: 'Inventories'

    },
    warranties: {
        title: 'DW Activations',
        columns: {
            photo: 'Photo',
            cardSequential: 'Card Sequential',
            sap: 'Code SAP',
            description: 'Descriptions',
            expiredDate: 'Expired Date',
            activationType: 'Activation Type',
            activationUser: 'Activation User/Store',
            storeUser: 'Store/User Ret',
            substitution: 'Substitution',
            serialNumber: 'Serial number',
            activation: 'Activation',
            return: 'Return',
            returnUser: 'Return User/Store',
            details: 'Details',
            operatingCountry:'Operating country/region',
            parent:'Parent',
            isoCode:'Iso code',
            edit:'Edit',
            returnDeactivate: 'Return/Deactivate',
            status: 'Status',
            storeCode: 'Store code'
        },
        fields:{
            sequentialCard: 'Sequential card',
            sapCode: 'SAP Code',
            serialNumber: 'Serial number',
            reso: 'Return',
            storeType: 'Store type',
            materialType: 'Material type',
            nameCodeStore: 'Name/Code store',
            operatingCountry: 'Operation country/region',
            activationType: 'Activation type',
            onBehalfOf: 'On Behalf of',
            masterCode: 'Master code',
            soldToParty: 'Sold to party',
            responsibilityArea: 'Responsibility area',
            isoCode:'Iso code',
            parent:'Parent',
            activationDate: 'Activation date',
            store: 'Store',
            user: 'User',
            add: 'Add new product',
            errors:{
                sapCode: 'Insert a valid SAP Code!',
                activationDate: 'Select a valid activation date!',
                serialNumber: 'Insert a valid serial number!',
                store: 'Insert a valid store!',
                user: 'Insert a valid user!',
                sequentialCard: 'Insert a valid sequential card!',
            },
            values: {
                all : 'All',
                reso : 'Return',
                noReso : 'No return',
                accessories : 'Accessories',
                watches : 'Watches',
                jewels : 'Jewels',
                fineJewelry : 'High Jewelry',
                manuale : 'Manual',
                appManual : 'APP Manual',
                yes : 'Yes',
                no : 'No',
                internal: 'Internal',
                external: 'External',
                prospect: 'Prospect',
                open: 'Open',
                closed: 'Closed',
                phaseOut: 'Phase Out',
                future: 'Future',
                notActivated: 'Not Activated',
                pending: 'Pending',
                activated: 'Activated'
            },
        },
        add : {
            success: 'Activation added successfully'
        },
        changeExpiration:{
            success: 'Expiration date successfully updated',
            error: 'Update failed',
            errorDescription: 'Expiration date has not been updated'
        },
        warning: 'Serial and SAP Code not compatible',
        error: {
            watchInvalidSerialTitle: 'Serial e SAP Code not consistent',
            watchInvalidSerialDescription: 'Serial e SAP Code are not consistent for selected Watch.',
            warrantyAlreadyPresentTitle: 'Warranty already present',
            warrantyAlreadyPresentDescription: 'The warranty is already present for the selected product.'
        }
    },
    partnerinventories:{
        fields:{
            inventoryDateFrom : 'Inventory Date From',
            inventoryDateTo: 'Inventory Date To',
            sapCode: 'Sap Code',
            serialNumbers: 'Serial',
            store: 'Store',
            soldTo: 'Sold To',
            creationDateFrom: 'Creation Date From',
            creationDateTo: 'Creation Date To',
            lastInventory: 'Last Inventory'
        },
        columns:{
            inventoryDate : 'Inventory Date',
            serialNumber: 'Serial Number',
            store: 'Store',
            soldTo: 'Sold to',
            product: 'Product',
            creationDate: 'Creation Date',
            storeCode: 'Store Code',
            quantity: 'Quantity',
            sapCode: 'SAP Code'
        },
        button:{
            upload: 'Upload Inventory',
            download: 'Download template'
        }
    },
    prewarranties:{
        fields: {
            activationDate: 'Pre activation date'
        },
        add : {
            success: 'Pre activation added successfully'
        },
        details: {
            image: "Image",
            product: "Product",
            date: "Date",
            store: "Store",
            user: "User",
            delete: "Delete Preactivation",
            activation: "Activation",
        },
         error: {
            prewarrantyAlreadyPresentTitle: 'PreWarranty already present',
            prewarrantyAlreadyPresentDescription: 'The preWarranty is already present for the selected product.',
            checkSerialSAPErrorTitle: 'Seriale and SAP Code not consistent',
            checkSerialSAPErrorDescription: 'The Serial and SAP Code are not consistent.'
        }
    },
    hierarchies:{
        add:'Add new area',
        success:'New area added successfully',
        entity: {
            singular_name: "New area",
            plural_name: "New area"
        },
        edit: {
            modify: 'Edit area',
            success: 'Area edited successfully'

        }
    },
    settings: {
        choose_language: 'Choose your language',
        language: 'Language',
    },
    stores: {
        fields: {
            code: "Code",
            telephone: "Telephone",
            state: "State",
            bvlAfss: "Bvl Afss",
            openingDate: "Opening Date",
            closingDate: "Data di Chiusura",
            phaseOutDate: "Phase Out Date",
            phaseOut: "Phase Out",
            soldToParty: "Sold to Party",
            responsabilityArea: "Responsability Area",
            masterCodeCode: "MasterCode Code",
            masterCodeName: "MasterCode Name",
            soldTo: "Sold To",
            shipTo: "Ship To",
            operatingCountry: "Operating Country/Region",
            areaDefaultEmail: "Area Default Email",
            nfcReader: "Devices USB NFC Reader",
            smartphone: "Devices Smartphone",
            created: 'Store created'
        },
        details: {
            activateStore: "Activate Store",
            sendAgain: "Send Again",
            activateSmartphone: "Activate Smartphone",
            requestActivationSuccess: "Activation request happened successfully",
            activationSuccess: "Store activated successfully!",
            activate: "Activate",
            askActivation: "Confirm Activation Store",
            askActivationDescription: "Do you really want to activate this store:"
        }
    },
    profiles:{
        columns: {
            profileName: 'Profile Name',
            jobId: 'Job ID',
            departmentId: 'Department ID',
            jobDescription: 'Job description',
            departmentDescription: 'Description department',
            storeType: 'Store type',
            storeId: 'DW store',
            dwArea: 'DW area',
            edit: 'Edit',
        },
        fields: {
            profileName: 'Profile Name',
            jobId: 'Job ID',
            departmentId: 'Department ID',
            jobDescription: 'Job description',
            departmentDescription: 'Description department',
            storeType: 'Store type',
            storeId: 'Name/Code store',
            dwArea: 'DW area',
            add: 'Add new profile',
            edit: 'Edit profile',
            delete: 'Delete profile'
        },
        upload:{
            label:"You can upload your xls files to convert them in profiles.",
            name:"Click or drag file to this area to upload",
            error:"File upload failed.",
            success:"File uploaded successfully."
        }
    },
    manual:{
        columns: {
            photo: 'Photo',
            sapCode: 'SapCode',
            instruction: 'Manual',
            brochure: 'Brochure',
        },
        fields: {
            sapCode: 'SapCode'
        }
    },
    help:{
        ask_support: 'REQUEST SUPPORT',
        how_to_use: 'HOW TO USE',
        how_to_use_video: 'Watch the video below to have a quick guide on how to use the Digital Warranty features.',
        sent: 'Email sent',
        not_sent:'Email not sent',
        sales_activate_watches:'HOW TO USE A SALES TAG TO ACTIVATE WATCHES',
        shipping_activate_watches:'HOW TO USE A SHIPPING TAG TO ACTIVATE JEWELRY',
        sales_activate_accessories:'HOW TO USE A SALES TAG TO ACTIVATE ACCESSORIES',
        active_watches_type:'To activate Watches, type:',
        sap_code:'SAP CODE',
        serial_number: 'SERIAL NUMBER'
    },
    splash:{
        welcome:"Welcome to",
        presentation: "DIGITAL WARRANTY & AUTHENTICITY is an application designed to manage authenticity and guarantees in a completely digital way. Get on board.",
        enter:'Enter'
    },
    info:{
        header:{
            watches: 'WATCHES – WARRANTY INFORMATION',
            accessories: 'ACCESSORIES – AUTHENTICITY INFORMATION',
            jewellery_pieces: 'JEWELLERY PIECES – INFORMATION ON BULGARI INTERNATIONAL AUTHENTICITY AND WARRANTY',
            high_jewellery: 'HIGH JEWELLERY – AUTHENTICITY INFORMATION'
        },
        body:{
            watches: 'This watch is a BVLGARI creation manufactured by Bulgari Horlogerie SA, rue de Monruz 34, Neuchatel, Switzerland, in accordance with the highest quality standards of the Swiss watchmaking industry. The warranty activated during your purchase will be honoured everywhere in the world and covers any manufacturing defect. The present warranty exclusively applies to products purchased from the official BVLGARI sales network, at BVLGARI boutiques or from BVLGARI’s authorized retailers. Please refer to the specific warranty conditions of your product, accessible by scanning the QR code on the card, or via its NFC function. Your BVLGARI watch is covered by this warranty only if the international warranty digital card has been duly activated, completed and dated by the vendor at the time of purchase. During the warranty period, your watch will be repaired free of charge in case of manufacturing defects. Normal wear and tear of the watch and its accessories are not covered by this warranty, nor are events of loss, theft, misuse or negligence. The warranty will be invalid in case of illegible or erased serial number, or in case of any modifications or attempted repairs to the watch by unauthorized third parties. This warranty does not cover any indirect or consequential damage arising from malfunctioning, defects or lack of precision of the watch.\\n\\nImportant: for any maintenance or repair, bring your BVLGARI watch with its warranty card to one of our stores, or to one of our authorized dealers.\\n\\nYou can scan the QR code on your international warranty and authentication card, or use its NFC function, to access the user manual and the care recommendations of your product, as well as the warranty conditions and its duration. This international warranty does not affect any other rights you might have under current applicable legislation.',
            accessories: 'This product is an original creation of BVLGARI, created and checked according to the most rigorous quality tests. Activating the card all manufacturing defects are covered for two years from the date of purchase anywhere in the world. The Bulgari product contains a passive Tag NFC (Near Field Communication). In this Tag You will find some information about the product (unique ID of chip, product code, digital signature of authenticity) to guarantee the authenticity of it. This Tag can be read by a NFC device. This Tag does not contain any personal data nor can identify who detain this product. For more information contact privacy@bulgari.com',
            jewellery_pieces: 'This jewellery piece is an original creation of BULGARI, manufactured and checked according to the most rigorous quality tests. It is associated with an International Warranty and Authenticity Card, to be activated on the date of purchase from a BULGARI Boutique or an official retailer of BULGARI jewellery pieces. The International Warranty covers all manufacturing defects for a period of 24 months from the date of purchase and is valid anywhere in the world, provided that it has been duly activated upon purchase. The serial number engraved on the jewellery piece must be entered on the warranty card. If the card is not activated, the warranty does not apply. In addition, the warranty does not apply if the serial number engraved on the jewellery piece is not clearly legible, has been altered or erased. During the warranty period, your jewellery piece will be repaired free of charge in case of any manufacturing defects. This warranty does not cover the normal wear and tear of the jewellery piece, nor defects or damages resulting from misuse, mishandling, negligence or accident. This warranty does not cover cases of loss or theft. In addition to the above, this warranty does not apply in case of unauthorised alteration or attempt of repair performed by anyone other than a BULGARI Boutique or an official retailer of BULGARI jewellery pieces.\n' +
                'IMPORTANT: To request a service, please bring your BULGARI creation together with the Warranty and Authenticity Card to a BULGARI Boutique or to an official retailer of BULGARI jewellery pieces. You can access the BULGARI care instructions and documents associated with your jewellery piece by scanning the QR code on your International Warranty and Authenticity Card or by using NFC technology. This International Warranty does not affect any other rights you may have under any applicable national legislation',
            high_jewellery: 'This product is an original creation of BVLGARI, created and checked according to the most rigorous quality tests. Activating the card all manufacturing defects are covered for two years from the date of purchase anywhere in the world.'
        }
    },
    return: {
        title: "Insert serial or Sap code for HJ only",
        return: "Return",
        fields: {
            serial: "Serial",
            next: "Next",
            required: "Serial is mandatory"
        },
        choiceProduct: "Choose Product for the Return",
        summaryProduct: "Summary Product",
        codeSAP: "Code SAP",
        activationDate: "Activation Date",
        store: "Store",
        expiryDate: "Expiry Date",
        duration: "Duration",
        reasonReturn: "Write the reason in the form below",
        errorTitle: "Return not available",
        errorSubTitle: "The return of this product is not authorized.",
        imageProduct: "Product Image",
        infoProduct: "Info Product",
        infoActivation: "Info Activation",
        warrantyExpired: "Warranty Expired",
        successTitle: "Return with Success !",
        successSubtitle: "The Return of the selected product was successful."
    },
      massive_activation:{
        label:"You can upload your xls files to convert them in activations.",
        name:"Click or drag file to this area to upload",
        error:"File upload failed.",
        success:"File uploaded successfully."
    },
    status: {
        title: "Insert serial or Sap code for HJ only",
        status: "Status",
        fields: {
            serial: "Serial",
            next: "Next",
            required: "Serial is mandatory",
            email: "Email",
            emailDescription: "multiple emails separated by ',' (press enter to confirm)"
        },
        choiceProduct: "Choose Product",
        summaryProduct: "Summary Product",
        codeSAP: "Code SAP",
        activationDate: "Activation Date",
        store: "Store",
        user: "User",
        expiryDate: "Expiry Date",
        duration: "Duration",
        reasonReturn: "Write the reason in the form below",
        errorTitle: "STatus not available",
        errorSubTitle: "The status of this product is not authorized.",
        imageProduct: "Product Image",
        infoProduct: "Info Product",
        infoActivation: "Info Activation",
        warrantyExpired: "Warranty Expired",
        successTitle: "Status obtained with Success !",
        successSubtitle: "The status of the selected product was obtained with success.",
        manualProduct: "Print Product Manual",
        versionPdf: "Version PDF",
        returnDate: "Return Date",
        versionPdfTitle: "Summary for the Product",
        versionPdfSubTitle: "Download PDF on your device, or send it to the customer who just activated the product",
        sendEmail: "Send for Email",
        downloadPdf: "Download PDF",
        sendEmailSuccess: "Email Sended with Success!",
        manualProductTitle: "Product Manual",
        manualProductSubTitle: "Download PDF on your device",
        instructions: "Instructions",
        care: "Care",
        infoExpiryDate: "Info Expiry Date",
        changeExpiryDate: "Change Expiry Date",
        confirmChangeExpiryDate: "Confirm",
        serialNumberError: "Wrong Serial Number!",
        serialNumberErrorMsg: "The serial number does not exist"
    },
    users: {
        fields: {
            managedStores: 'Managed Stores',
            managedStoreTypes: 'Managed Store Types'
        },
        columns: {
            storeCode: 'Store Code',
            role: 'Role'
        }
    },
    landing: {
        sapCode: 'SAP Code',
        expiration_date: 'Expiration Date',
        warranty_duration: 'Warranty duration',
        download_manual: 'Download Manual',
        download_brochure: 'Download Brochure',
        extend_warranty: 'Extend Warranty',
        sp_pt_disclaimer: 'This international warranty does not affect any other rights you may have under any applicable national legislation.'
    }
}
