import { EditOutlined, ReloadOutlined, ScheduleOutlined, SearchOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { FormLayout } from "antd/lib/form/Form";
import moment from 'moment';
import { ReactNode } from "react";
import CrudDetails from "../../components/CrudDetails";
import CrudModule from "../../components/CrudModule";
import { FormRow } from "../../components/form/DynamicForm";
import { MenuNode } from "../../components/MenuComponent";
import PageViewer from "../../components/PageViewer";
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { addFilter } from "../../services/HooksManager";
import { successToast } from "../../services/NotificationManager";
import ParentModule from "../../shared/module/CrudModule";
import { navigate } from "../../shared/router";
import { __ } from "../../translations/i18n";
import ProfileAdd from "../components/profile/ProfileAdd";
import ProfileEdit from "../components/profile/ProfileEdit";
import ProfileUpload from "../components/profile/ProfileUpload";
import { __b } from "../translations/i18n";
import ObjectUtils from "../utils/ObjectsUtil";


class ProfileModule extends ParentModule {


    init = () => {
        super.init();
        addFilter ( 'app_sub_menu', this.addMenu );

        addFilter ( 'app_routes', ( routes: any ) => {
            routes[this.getRoute()] = {
                component: PageViewer,
                props: { content: <CrudModule tabs={['listing','create','upload']} module={ this }/>, selectedKey: this.getRoute() },
                visibility: 'private'
            };
            routes[this.getRouteDetail()] = {
                component: PageViewer,
                props: { content: <CrudDetails module={ this }/> },
                visibility: 'private'
            };
            return routes;
        } );


        addFilter ( 'search_form_data_' + this.getModuleKey (), this.getSearchData );
        addFilter ( 'crud_details_tabs_' + this.getModuleKey (), this.getTabs );
        addFilter('crud_create_component_' + this.getModuleKey(), this.getAddComponent);
        addFilter('crud_detail_edit_component_' + this.getModuleKey(), this.getEditComponent);
        addFilter('crud_upload_component_' + this.getModuleKey(), this.getUploadComponent);
    }

    addMenu = ( menu: MenuNode[] ) => {
        menu.push ( {
            label: <i >{ __b ( 'menu.profiles' ) }</i>,
            icon: <ScheduleOutlined />,
            position: '32',
            key: this.getRoute()
        } );

        return menu;
    }

    getModuleKey = (): string => {
        return "profiles";
    }

    getAddComponent = (component: ReactNode, entity: any) => {
        const roles = fetchAllRoles();
        return (<ProfileAdd module={this.getModuleKey()} add={(data) => this.add(data, roles)} />);
    }

    add = async ( data: any, roles: any ) => {
        const provider = DWEnvironmentManager.getDataProvider ();

        const role = await roles;

        // ------ parametri fissi non settabili
        data.profileType = 'PROFILE'
        data.syncCode = ''
        data.attributes = { expectedDevicesReaders: 0, expectedDevicesSmartphones: 0 }
        // ------

        const roleId = data.role;
        if (!!roleId){
            data.role = role.find(({value}:any) => value === roleId).item
        }
        delete data.storeId;

        const req = {
            data: Object.assign ( {}, data ),
            headers: {
                'Content-Type': 'application/json'
            },
        };

        const res = await provider.addOne ( req, 'profiles' )

        if( res ) {
            successToast(__('common.success'), __('profile.add.success'));
            navigate ( '/profile' );
        }
    }


    getEditComponent = (component: ReactNode, entity: any) => {
        const roles = fetchAllRoles();
        return (<ProfileEdit entity={entity} module={this.getModuleKey()} edit={(data) => this.edit(data, entity, roles)} />);
    }

    edit = async ( data: any, entity: any, roles: any ) => {
        const provider = DWEnvironmentManager.getDataProvider ();

        const role = await roles;

        // ------ parametri fissi non settabili
        data.profileType = 'PROFILE'
        data.syncCode = ''
        data.attributes = { expectedDevicesReaders: 0, expectedDevicesSmartphones: 0 }
        // ------
        delete data.editProfile;
        data.id = entity.id;

        const roleId = data.roleId.value;
        if (!!roleId){
            data.role = role.find(({value}:any) => value === roleId).item
        }

        delete data.roleId;

        const req = {
            data: Object.assign ( {}, data ),
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const res = await provider.editOne ( req, `profiles/${ entity.id }` );

        if( res ) {
            successToast(__('common.success'), __('profiles.edit.success'));
            navigate ( '/profile' );
        }
    }


    getUploadComponent = (component: ReactNode, entity: any) => {
        return (<ProfileUpload />);
    }

    // remove details tabs for this entity
    getTabs = ( tabs: any, module: any ) => {

        tabs.splice(0,1);
        return tabs;
    }

    __getFormFields = (): FormRow[] => {

        return [
            {
                fields: [
                    {
                        name: 'roleId',
                        label: __b ( 'profiles.fields.profileName' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchRoles ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobId',
                        label: __b ( 'profiles.fields.jobId' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentId',
                        label: __b ( 'profiles.fields.departmentId' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobDescription',
                        label: __b ( 'profiles.fields.jobDescription' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentDescription',
                        label: __b ( 'profiles.fields.departmentDescription' ),
                        type: 'text',
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'externalCode',
                        label: __b ( 'profiles.fields.storeType' ),
                        type: 'select',
                        mode: 'multiple',
                        required: false,
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: "Ownership", value: 'ownership' },
                            { label: "Wholesale", value: 'wholesale' },
                            { label: "Travel Retail", value: 'travel retail' },
                            { label: "Franchisee", value: 'franchisee' },
                            { label: "Service Center", value: 'service center' },
                            { label: "Family and Friends", value: 'family and friends' },
                            { label: "Vintage", value: 'vintage' },
                            { label: "Online", value: 'online' }
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'store',
                        label: __b ( 'profiles.fields.storeId' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchStores ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'hierarchy',
                        label: __b ( 'profiles.fields.dwArea' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchHierarchies ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: __ ( 'common.reset' ),
                        type: 'button',
                        htmlType: 'reset',
                        block: 'true',
                        buttonType: 'default',
                        icon: <ReloadOutlined />,
                        colConf: { span: 2, offset: 10 }
                    },
                    { name: __ ( 'common.search' ), type: 'button', htmlType: 'submit', block: 'true', icon: <SearchOutlined />, colConf: { span: 2 } }
                ]
            }
        ];

    }

    getSearchData = ( data: { [ key: string ]: any } ) => {

        let dataClone: { [ key: string ]: any } = {};
        Object.keys ( data ).map ( ( key ) => {
            if( moment.isMoment ( data[ key ] ) ) {
                return dataClone[ key ] = data[ key ].format ( 'YYYY/MM/DD' );
            } else {
                return dataClone[ key ] = data[ key ];
            }
        } );

        return dataClone;
    }

    __getColumnsTable = () => {
        return [
            {
                title: __b ( 'profiles.columns.profileName' ),
                dataIndex: 'profileName',
                sorter: true,
                key: 'profileName'
            },
            {
                title: __b ( 'profiles.columns.jobId' ),
                dataIndex: 'jobId',
                sorter: true,
                key: 'jobId'
            },
            {
                title: __b ( 'profiles.columns.departmentId' ),
                dataIndex: 'departmentId',
                sorter: true,
                key: 'departmentId'
            },
            {
                title: __b ( 'profiles.columns.jobDescription' ),
                dataIndex: 'jobDescription',
                sorter: true,
                key: 'jobDescription'
            },
            {
                title: __b ( 'profiles.columns.departmentDescription' ),
                dataIndex: 'departmentDescription',
                sorter: true,
                key: 'departmentDescription'
            },
            {
                title: __b ( 'profiles.columns.storeType' ),
                dataIndex: 'storeType',
                key: 'storeType'
            },
            {
                title: __b ( 'profiles.columns.storeId' ),
                dataIndex: 'storeId',
                key: 'storeId'
            },
            {
                title: __b ( 'profiles.columns.dwArea' ),
                dataIndex: 'dwArea',
                key: 'dwArea'
            },
            {
                title: __b ( 'profiles.columns.edit' ),
                dataIndex: 'id',
                key: 'id',
                render: ( id: string | number ) => (
                    <Button onClick={ () => navigate (this.getRouteDetail(id)) } icon={ <EditOutlined/> } size={ "small" }
                            shape={ "circle" } type="primary" />
                )
            }
        ];
    }

    __getMapData = ( row: any ) => {

        return {
            jobId: row.jobId,
            jobDescription: row.jobDescription,
            profileName: row.role?.description,
            departmentId: row.departmentId,
            departmentDescription: row.departmentDescription,
            storeId:  <div>{row?.stores[ 0 ]?.code ?? ''} - {row?.stores[ 0 ]?.description ?? ''}</div>,
            dwArea: row?.hierarchies[ 0 ]?.description,
            storeType: row.storeTypes?.join ( ',' ),
            id: row.id
        }
    }

    __getLayout = (): FormLayout => {
        return "vertical";
    }

    fetchHierarchies = async ( key: string ) => {
        return await fetchHierarchies ( key );
    }

    fetchStores = async ( key: string ) => {
        return await fetchStores ( key );
    }

    fetchRoles = async ( key: string ) => {
        return await fetchRoles ( key );
    }
}


export const fetchHierarchies = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies' )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: res.description,
                value: res.code,
            } ) ),
        );
}

export const fetchStores = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();
    const moduleManager = DWEnvironmentManager.getModuleManager ();
    const storeModule = moduleManager.getModule ( 'stores' );

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey () )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: `${ res.code } - ${ res.description }`,
                value: `${ res.code }-${ res.description }`,
            } ) ),
        );
}

export const fetchRoles = async ( key: string ) => {


    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        'roles' )
        .then ( ( response ) =>
            ObjectUtils.stringIncludesDescription ( response, key )
        );
}

export const fetchAllRoles = async () => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray : any[] = []
    await provider.getList(0,0,{}, 'roles')
        .then((response) =>{
            response.forEach((element : any) => {
                rolesArray.push({label:element.code , value:element.id, item:element})
            });

        });
    return rolesArray
}

const profileModule = new ProfileModule ();
export default profileModule;
