import { Component } from "react";
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { __ } from "../../../translations/i18n";
import { CallRequestConfig } from "../../../shared/api";
import { FormRow } from "../../../components/form/DynamicForm";
import { __b } from "../../translations/i18n";
import ObjectUtils from "../../utils/ObjectsUtil";
import CrudForm from "../../../components/CrudForm";
import { closeModal, successToast, warningModal } from "../../../services/NotificationManager";
import { navigate } from "../../../shared/router";
import DWDataProviderImp from "../../providers/implementation/DWDataProviderImp";

interface Props {
    entity: any,
    module: string,
    edit: (data: any) => any
}

interface State {
    users: any,
    loadedUser: boolean
    roles: any[]
}

export default class ProfileEdit extends Component<Props, State> {

    loadAll = async () => {
        const roles = await fetchAllRoles()
        this.setState({roles: roles})
    }


    state: State = {
        users: [],
        loadedUser: false,
        roles: []
    }

    provider = DWEnvironmentManager.getDataProvider ();

    async componentDidMount(): Promise<void> {
        await this.loadUser ();
        await this.loadAll();
    }

    loadUser = async () => {
        const config: CallRequestConfig<any> = {
            params: {
                storeId: this.props.entity.id
            }
        }
        const userList = await this.provider.getList(undefined, undefined, config, 'users' );
        if (userList) {
            const users = userList.map( (user: any) => { return {label: user.username, value: user.id}});
            this.setState({users, loadedUser: true});
        }
    }


    getEditFormFields = (): FormRow[] => {


        return [
            {
                fields: [
                    {
                        name: 'roleId',
                        label: __b ( 'profiles.fields.profileName' ),
                        type: 'autocompleteselect',
                        required: false,
                        initialValue: { value:this.props.entity.role.id, label: this.props.entity.role.description },
                        fetchOptions: ( key: string ) => ( this.fetchRoles ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobId',
                        label: __b ( 'profiles.fields.jobId' ),
                        type: 'text',
                        defaultValue: this.props.entity.jobId,
                        value: this.props.entity.jobId,
                        required: false,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentId',
                        label: __b ( 'profiles.fields.departmentId' ),
                        type: 'text',
                        required: false,
                        defaultValue: this.props.entity?.departmentId,
                        value: this.props.entity?.departmentId,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'jobDescription',
                        label: __b ( 'profiles.fields.jobDescription' ),
                        type: 'text',
                        required: false,
                        defaultValue: this.props.entity?.jobDescription,
                        value: this.props.entity?.jobDescription,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'departmentDescription',
                        label: __b ( 'profiles.fields.departmentDescription' ),
                        type: 'text',
                        required: false,
                        defaultValue: this.props.entity?.departmentDescription,
                        value: this.props.entity?.departmentDescription,
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'storeTypes',
                        label: __b ( 'profiles.fields.storeType' ),
                        type: 'select',
                        required: false,
                        defaultValue: this.props.entity?.storeTypes.map((type: any) => {
                            return type
                        }),
                        mode: 'multiple',
                        selectValue: [
                            { label: __b ( "warranties.fields.values.all" ), value: '' },
                            { label: "Ownership", value: 'ownership' },
                            { label: "Wholesale", value: 'wholesale' },
                            { label: "Travel Retail", value: 'travel retail' },
                            { label: "Franchisee", value: 'franchisee' },
                            { label: "Service Center", value: 'service center' },
                            { label: "Family and Friends", value: 'family and friends' },
                            { label: "Vintage", value: 'vintage' },
                            { label: "Online", value: 'online' }
                        ],
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'storeId',
                        label: __b ( 'profiles.fields.storeId' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        fetchOptions: ( key: string ) => ( this.fetchStores ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                    {
                        name: 'hierarchyId',
                        label: __b ( 'profiles.fields.dwArea' ),
                        type: 'autocompleteselect',
                        required: false,
                        mode: 'multiple',
                        initialValue: this.props.entity?.hierarchies[0]?.description,
                        fetchOptions: ( key: string ) => ( this.fetchHierarchies ( key ) ),
                        colConf: {
                            span: 8
                        }
                    },
                ]
            },
            {
                style: {
                    className: "buttonForm"
                },
                fields: [
                    {
                        name: 'editProfile',
                        label: __b( 'profiles.fields.edit' ),
                        type: 'button',
                        htmlType: 'submit',
                        colConf: { span: 2, offset: 4 }
                    },
                    {
                        name: 'deleteProfile',
                        label: __b( 'profiles.fields.delete' ),
                        type: 'button',
                        htmlType: 'button',
                        onClick: () => this.deleteItem(),
                        colConf: { span: 2, offset: 10 }
                    }
                ]
            }
        ];
    }

    deleteItem = () => {

        if( this.props.entity?.id ) {
            warningModal ( {
                title: __ ( "warning.ask_delete" ),
                content: __ ( "warning.unreversible_delete" ),
                onOk: args => {
                    DWDataProviderImp.deleteOneBu( 'profiles', this.props.entity.id,).then (
                        ( res: any ) => {
                            closeModal ();
                            successToast ( __ ( "success.confirm_delete" ), '' );
                            navigate ( '/profile' );
                        }
                    )
                }
            } );
        }
    }

    fetchHierarchies = async ( key: string ) => {
        return await fetchHierarchies ( key );
    }

    fetchStores = async ( key: string ) => {
        return await fetchStores ( key );
    }

    fetchRoles = async ( key: string ) => {
        return await fetchRoles ( key );
    }

    render() {
        const { users } = this.state;
        const { loadedUser } = this.state;
        const { module } = this.props
        if( users.length <= 0 && !loadedUser ) {
            return <div/>
        }
        return ( <CrudForm module={ module } callback={ this.props.edit } getFormFields={ this.getEditFormFields } cardSize={ 12 } type={"edit"} /> )
    }
}


export const fetchHierarchies = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, 'hierarchies' )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: res.description,
                value: res.id,
            } ) ),
        );
}

export const fetchStores = async ( key: string ) => {

    const provider = DWEnvironmentManager.getDataProvider ();
    const moduleManager = DWEnvironmentManager.getModuleManager ();
    const storeModule = moduleManager.getModule ( 'stores' );

    return provider.getList ( 20, 0, {
        params: {
            description: key,
            orderBy: 'DESCRIPTION'
        }
    }, storeModule.getModuleKey () )
        .then ( ( response ) =>
            response.map ( ( res: any ) => ( {
                label: `${ res.code } - ${ res.description }`,
                value: res.id,
            } ) ),
        );
}

export const fetchRoles = async ( key: string ) => {


    const provider = DWEnvironmentManager.getDataProvider ();

    return provider.getList ( 20, 0, {},
        'roles' )
        .then ( ( response ) =>
            ObjectUtils.stringIncludesDescription ( response, key )
        );
}


export const fetchAllRoles = async () => {

    const provider = DWEnvironmentManager.getDataProvider();
    let rolesArray : any[] = []
    await provider.getList(0,0,{}, 'roles')
        .then((response) =>{
            response.forEach((element : any) => {
                rolesArray.push({label:element.code , value:element.id, item:element})
            });

        });
    return rolesArray
}


