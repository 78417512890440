import { Button, Col, Form, Image, message, Row, Tabs, Typography, Upload, UploadFile, UploadProps } from 'antd';
import React, { useState } from 'react';
import DWEnvironmentManager from "../../services/DWEnvironmentManager";
import { __b } from "../translations/i18n";
import { PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { ArgsProps } from "antd/lib/notification";
import { toast } from "../../services/NotificationManager";
import DWDataProviderImp from "../providers/implementation/DWDataProviderImp";
import dWUserManager from "../../services/DWUserManager";
import { applyFilters } from "../../services/HooksManager";

export const Help: React.FC = () => {

    const storeAddress = "ONLINE";
    const storeCode = "11E";
    const storeCountry = "Germany";
    const storeName = "E-Commerce DE";
    const videoUrl = applyFilters('help_video_url', DWEnvironmentManager.getStoragePath ()  + 'digital-warranty/assets/video/bulgari-dw-tutorial-desktop.mp4' );

    const configToast: ArgsProps = {
        message: '',
        description: '',
        type: 'error',
        placement: 'bottomRight'
    }

    const [ imgBase64, setImgBase64 ] = useState<any> ( [] );
    const [ message, setMessage ] = useState<any> ( '' );
    const [ disabledButton, setDisabledButton ] = useState<boolean> ( true );

    const onFinish = async () => {

        const user = await dWUserManager.getLoggedUser ();
        if( user ) {
            let imgsList: any = []

            if( imgBase64 ) {
                imgBase64.map ( ( img: string ) => {
                    imgsList.push ( img.split ( ',' )[ 1 ] );
                } );
            }

            DWDataProviderImp.mailSend ( {
                headers: { 'content-type': 'application/json;charset=UTF-8' },
                data: {
                    attachmentsBase64: imgsList,
                    message: message,
                    user: user,
                    storeAddress: storeAddress,
                    storeCode: storeCode,
                    storeCountry: storeCountry,
                    storeName: storeName,
                }
            } ).then ( ( result ) => {
                if( result.value ) {

                    const configToast: ArgsProps = {
                        message: __b ( 'help.sent' ),
                        description: '',
                        type: 'success',
                        placement: 'bottomRight'
                    }

                    toast ( configToast );
                } else {
                    configToast.message = __b ( 'help.not_sent' )
                    toast ( configToast );
                }
            } )
        }
    };

    const onFinishFailed = ( errorInfo: any ) => {
        console.log ( 'Failed:', errorInfo );

        configToast.message = errorInfo.toString ()
        toast ( configToast );
    };

    const handleUpload = ( info: any ) => {

        // read uploaded img
        let reader = new FileReader ();
        reader.readAsDataURL ( info.file.originFileObj );

        //check how convert the img in base64
        reader.onload = function () {
            if( reader.result && !imgBase64.includes ( reader.result.toString () ) ) {
                setImgBase64 ( [ ...imgBase64, reader.result.toString () ] )
            }
        };

        reader.onerror = function ( error ) {
            configToast.message = error.toString ()
            toast ( configToast );
        };
    };

    const handleTextArea = ( text: string ) => {
        setMessage ( text );

        if( text ) {
            setDisabledButton ( false );
        } else {
            setDisabledButton ( true );
        }
    }

    return (
        <div className='helpContent'>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane className="faqTab" tab="FAQ" key="1">
                    {/* ---- first row ----*/ }
                    <h2>{ __b ('help.sales_activate_watches') }</h2>
                    <Row style={ { display: "flex", justifyContent: 'space-around' } }>
                        <Col>
                            <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                                   src={ DWEnvironmentManager.getStoragePath () + 'digital-warranty/assets/img/faq1.png' }/>
                        </Col>
                        <Col>
                            <Typography>{__b('help.active_watches_type')}</Typography>
                            <ol>
                                <li>{__b('help.sap_code')}</li>
                                <li>{__b('help.serial_number')} (S/N)</li>
                            </ol>
                        </Col>
                    </Row>

                    <div className='tipsContent'>
                        <h2>TIPS</h2>
                        <div>
                            <ul>
                                <li><b>{__b('help.sap_code')}</b>: is the code that identifies the product model.</li>
                                <li><b>{__b('help.serial_number')}</b>: is a code that identifies an individual product.</li>
                            </ul>
                        </div>

                        <ol>
                            <li>
                                <div><b>Q</b>: Activation | Is it possible to activate Watches by reading the NFC
                                    internal
                                    chip?
                                </div>
                                <b>A</b>: No, it is not possible because Watches do not have the embedded chip. You can
                                only
                                activate by manual input.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Activation | Is it possible to use a dummy S/N when activating Watches
                                    manually?
                                </div>
                                <b>A</b>: No, it is not possible. Watches must be activated by inserting the Sap Code
                                and
                                the Serial Number written on Sales Tag.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Substitution | Can I read the product tag to substitute a Product Card?
                                </div>
                                <b>A</b>: No, to substitute a Product Card you can only use the option “Insert Serial
                                Number”.
                            </li>
                        </ol>
                    </div>
                    {/*---- end first row ----*/ }

                    {/* ---- second row ----*/ }
                    <h2 style={ { marginTop: '5%' } }>
                        { __b ('help.sales_activate_accessories') }
                    </h2>
                    <Row style={ { display: "flex", justifyContent: 'space-around' } }>
                        <Col>
                            <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                                   src={ DWEnvironmentManager.getStoragePath () + 'digital-warranty/assets/img/faq2.png' }/>

                        </Col>
                        <Col>
                            <Typography>In general, to activate Accessories consider one of the following
                                options:</Typography>
                            <ol>
                                <li>Tap Sales Tag</li>
                                <li>Tap the Embedded Product Tag (LLG & SLG)</li>
                                <li>Read QR Code</li>
                                <li>Insert SAP Code*</li>
                            </ol>
                        </Col>
                    </Row>

                    <div className='tipsContent'>
                        <h2>TIPS</h2>
                        <div>
                            <ul>
                                <li><b>{__b('help.sap_code')}</b>: is the code that identifies the product model.</li>
                                <li><b>{__b('help.serial_number')}</b>: is the unique code of the NFC embedded tag that identifies an
                                    individual product.
                                </li>
                            </ul>

                            <Typography>* In this case a dummy serial number will be generated.</Typography>
                        </div>

                        <ol>
                            <li>
                                <div><b>Q</b>: Sales tag issues | In case of data retrieval not available by using the
                                    sales
                                    tag, which procedure should be followed in order to activate successfully the card?
                                    chip?
                                </div>
                                <div><b>A</b> <u>Mobile app</u>: on the activation screen, you can retrieve data by
                                    tapping
                                    with the smartphone on the backside center of product to read from the internal NFC
                                    chip.
                                </div>
                                <u>Desktop app</u>: on the activation screen, you can type manually SAP code and a dummy
                                S/N
                                will be automatically generated.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Sales tag issues | In case the system reports the error message
                                    “Attention!
                                    Tag Not Valid” when reading the sales tag, which procedure should be followed in
                                    order
                                    to report the problem and activate successfully the card?
                                </div>
                                <div>
                                    <div><b>A</b> To report the issue open a ticket using the form below and attach a
                                        picture of the sales tag. Then proceed as follow:
                                    </div>
                                    <u>Mobile app</u>: Insert SAP Code manually to proceed with activation.
                                </div>
                                <u>Desktop app</u>: Insert SAP Code manually to proceed with activation.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Return #1 | How do I activate a card for a returned product that has no
                                    longer the sales tags?
                                </div>
                                <div>
                                    <b>A</b>
                                    <u>Mobile app</u>: on the activation screen, you can retrieve data by tapping the
                                    smartphone on the backside center of the product to read from the internal NFC chip.
                                </div>
                                <u>Desktop app</u>: on the activation screen, you can type manually SAP code and a dummy
                                S/N
                                will be automatically generated.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Return #2 | Is it possible to reuse the card of a returned product?
                                </div>
                                <div>
                                    <b>A</b>: No, it is not possible. In case you go out of stock, please follow the
                                    procedure currently used for watches cards.
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Sales tag exchange | Is it possible to swap sales tags among different
                                    products with same SAP code?
                                </div>
                                <div>
                                    <b>A</b>: No, it is not possible. Sales tags are strictly related to every single
                                    product because they embed product serial numbers (NFC chip serial number).
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Product with no embedded NFC chip (e.g. belts, bracelets) | Is there a
                                    serial
                                    number for these products?
                                </div>
                                <div>
                                    <b>A</b>: No. The card is activated with a dummy S/N generated by the system.
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: NFC internal chip | Which products do have NFC embedded chip and where is
                                    it
                                    located?
                                </div>
                                <div>
                                    <b>A</b>: Large and Small Leather Goods from FW17 onwards have NFC embedded chip and
                                    it
                                    is placed centered in the back side of our products.
                                </div>
                            </li>

                        </ol>
                    </div>
                    {/*---- end second row ----*/ }


                    {/* ---- third row ----*/ }
                    <h2>{ __b ('help.shipping_activate_watches') }</h2>
                    <Row style={ { display: "flex", justifyContent: 'space-around' } }>
                        <Col>
                            <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                                   src={ DWEnvironmentManager.getStoragePath () + 'digital-warranty/assets/img/faq3.png' }/>
                            <Typography>To activate Basic Jewelry
                                consider the following:</Typography>
                            <ul>
                                <li>If batch code is not provided on the shipping tag
                                    type <b>1. SAP Code + 2. Serial Number (S/N)</b></li>
                            </ul>
                        </Col>
                        <Col>
                            <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                                   src={ DWEnvironmentManager.getStoragePath () + 'digital-warranty/assets/img/faq4.png' }/>
                            <Typography>To activate Diamonds or Monete
                                consider the following:</Typography>
                            <ul>
                                <li>If batch code is provided on the shipping tag
                                    type <b>3. SAP Code + 4. Batch</b></li>
                            </ul>
                        </Col>
                    </Row>

                    <div className='tipsContent'>
                        <h2>TIPS</h2>
                        <div>
                            <ul>
                                <li><b>{__b('help.sap_code')}</b>: is the code that identifies the product model.</li>
                                <li><b>BATCH</b>: is a unique code that identifies Diamonds and Monete.</li>
                                <li><b>{__b('help.serial_number')}</b>: is a unique code that identifies Basic Jewelry.</li>
                            </ul>
                        </div>

                        <ol>
                            <li>
                                <div><b>Q</b>: Not all jewels have S/N, which is the procedure for product activation in
                                    this case?
                                </div>
                                <b>A</b>: Considering that the most of jewels have S/N, the general guidelines to
                                activate jewelry products are:
                                <div style={ {
                                    border: "solid 1px black",
                                    width: '25%',
                                    marginTop: '1%',
                                    padding: '2px'
                                } } className='borderItem'>
                                    1.If batch code is provided type <b>SAP Code + Batch</b>
                                </div>
                                <div style={ {
                                    border: "solid 1px black",
                                    width: '25%',
                                    marginTop: '1%',
                                    padding: '2px'
                                } } className='borderItem'>
                                    2.If batch code is <u>not</u> provided type <b>SAP Code + S/N</b>
                                </div>

                                <div style={ {
                                    border: "solid 1px black",
                                    width: '25%',
                                    marginTop: '1%',
                                    padding: '2px'
                                } } className='borderItem'>
                                    3. If both Batch and S/N are provided type <b>SAP Code + Batch</b>
                                </div>

                                <div style={ {
                                    border: "solid 1px black",
                                    width: '25%',
                                    marginTop: '1%',
                                    padding: '2px'
                                } } className='borderItem'>
                                    4. If batch code and S/N are missing release <b>paper certificate</b>
                                </div>
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: Where can I find Batch or Serial Number?
                                </div>
                                <b>A</b>: You can read Batch or Serial Number on the Shipping Tag or engraved inside the
                                product.
                            </li>
                            <br/>
                            <li>
                                <div><b>Q</b>: When do I have to release the paper certificate?</div>
                                <b>A</b>: The paper certificate has to be released when both Batch Code and S/N are
                                missing. In this case the Digital Card doesn’t have to be activated and the client will
                                receive the paper certificate only.
                            </li>
                        </ol>

                        <Typography>If you have any question about activation criteria for jewels (batch/serial), please
                            contact JBU After Sales Team:</Typography>
                        <a>Team_JBUtass@BULGARI.com</a>
                    </div>
                    {/*---- end third row ----*/ }

                    {/* ---- fourth row ----*/ }

                    <Row style={ { display: "flex", justifyContent: 'space-around' } }>
                        <Image fallback={DWEnvironmentManager.getNotFoundImg()}
                               src={ DWEnvironmentManager.getStoragePath () + 'digital-warranty/assets/img/faq5.png' }/>
                    </Row>

                    <Row>
                        <ul>
                            <li>The Digital Authenticity Cards are part of the product packing.</li>
                            <li>In case of need, <b>spare cards must be requested to Customer Service</b></li>
                            <li><u>CS creates a FOC order to </u> BGO (ref. BEIRE – Packaging DPT)</li>
                        </ul>
                    </Row>
                    {/*---- end fourth row ----*/ }

                    {/*---- form row ----*/ }
                    <Row style={ { display: "flex", justifyContent: 'center' } }>
                        <h2>{ __b ( 'help.ask_support' ) }</h2>
                    </Row>
                    <Form
                        name="basic"
                        labelCol={ { span: 8 } }
                        wrapperCol={ { span: 16 } }
                        initialValues={ { remember: true } }
                        onFinish={ onFinish }
                        onFinishFailed={ onFinishFailed }
                        autoComplete="off"
                    >
                        <Form.Item label="Upload" valuePropName="fileList">
                            <Upload action="/upload.do" listType="picture-card" onChange={ handleUpload }>
                                <div>
                                    <PlusOutlined/>
                                    <div style={ { marginTop: 8 } }>Upload</div>
                                </div>
                            </Upload>
                        </Form.Item>

                        <Form.Item label="TextArea">
                            <TextArea value={ message }
                                      onChange={ ( e ) => handleTextArea ( e.target.value ) } style={ { width: '50%' } }
                                      rows={ 4 }/>
                        </Form.Item>

                        <Form.Item wrapperCol={ { offset: 8, span: 16 } }>
                            <Button disabled={ disabledButton } type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>

                    {/*---- end form row ----*/ }


                </Tabs.TabPane>
                <Tabs.TabPane tab={ __b ( 'help.how_to_use' ) } key="2">
                    <Typography>{ __b ( 'help.how_to_use_video')}</Typography>
                    <div className='helpUrlContainer' style={{textAlign:'center', padding: '1.5% 0'}}>
                        <video height="450" controls>
                            <source src={videoUrl}  type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
};
