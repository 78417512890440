import { Button, Card, Col, Divider, Image, Layout, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import Link from "antd/lib/typography/Link";
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from "antd/lib/typography/Title";
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import warranty_info from '../../../assets/images/warranty_info.svg';
import DWEnvironmentManager from "../../../services/DWEnvironmentManager";
import { applyFilters } from "../../../services/HooksManager";
import i18n, { __ } from "../../../translations/i18n";
import DateUtils from "../../../utils/DateUtils";
import buEnvironmentManager from "../../services/BuEnvironmentManager";
import { __b } from "../../translations/i18n";
import {navigate} from "../../../shared/router";

interface Props {
    children?: React.ReactNode | JSX.Element | React.ReactNode[] | JSX.Element[];
}

const LanguageFormat: Record<string, string> = {
    it: 'it_IT',
    en: 'en_US',
    es: 'es_ES',
    fr: 'fr_FR',
    de: 'de_DE',
    pt: 'pt_PT',
    ru: 'ru_RU',
    ko: 'ko_KO',
    zh_Hant: 'zh_Hant',
    zh_Hans: 'zh_Hans',
    ja: 'ja_JA',
    ar: 'ar_AR'
}

const LandingPage = (props: Props) => {

    // We can use the `useParams` hook here to access
    // the dynamic pieces of the URL.
    let { id } = useParams<{ id: string }>();
    const [ready, setRedy] = useState(false)
    const [redirect, setRedirect] = useState<any>(false)
    const [product, setProduct] = useState<any>([])

    const moduleManger = DWEnvironmentManager.getModuleManager();
    const imgDownload = <Image preview={false} width={50} fallback={DWEnvironmentManager.getNotFoundImg()} src={ DWEnvironmentManager.getStoragePath () +'digital-warranty/assets/img/download_pdf_off.svg' } alt='download_pdf_off.svg'/>;

    const getBrowserLanguage = (): string => {
        const lang = navigator.language;
        const langPrefix = lang.split("-")[0];

        if (langPrefix === 'zh') {
            return lang === 'zh-TW' ? LanguageFormat.zh_Hant : LanguageFormat.zh_Hans;
        }

        return LanguageFormat[langPrefix] || LanguageFormat.en;
    }


    useEffect(() => {
        if (!ready) {
            const warrantyModule = moduleManger.getModule('warranties');
            warrantyModule.getWarrantyByBadge(id).then((res: any) => {
                if (res) {

                    if ((res.digitalPassportActive != undefined) && (res.digitalPassportActive === true)) {
                        window.location.replace(buEnvironmentManager.getTrackerV2Redirect() + res.uid);
                    } else {
                        if (res.serial === null) {
                            const preWarrantyModule = moduleManger.getModule('prewarranties');
                            preWarrantyModule.getPreWarrantyByBadge(id).then((res: any) => {
                                if (res.value) {
                                    window.location.replace(buEnvironmentManager.getTrackerRedirect() + res.uid);
                                } else {
                                    window.location.replace(DWEnvironmentManager.getMainSite())
                                }
                            });
                        } else {
                            if (res.preActivation || res.saveTheChildreRedirect) {
                                window.location.replace(buEnvironmentManager.getTrackerRedirect() + res.uid);
                            }
                        }
                        setProduct(res);
                    }
                } else {
                    const preWarrantyModule = moduleManger.getModule('prewarranties');
                    preWarrantyModule.getPreWarrantyByBadge(id).then( (res: any) => {
                        if (res.value) {
                            window.location.replace(buEnvironmentManager.getTrackerRedirect() + id);
                        } else {
                            window.location.replace(DWEnvironmentManager.getMainSite())
                        }
                    });
                }

                setRedy(true);
            })
        }
    })

    const goToExtendedBulgari = () => {
        window.location.replace(buEnvironmentManager.getExtendedRedirect());
    }

    const getProductDescription = () => {
        const lang = i18n.language.substring(0,2).toUpperCase();
        const description = product?.attributes[`MarketingLong${lang}`]?.value;
        if (description) {
            return description;
        } else {
            return product?.extendedProductDescription;
        }
    }

    const attributes = () => {
        let attributes = {
            image: <Image fallback={DWEnvironmentManager.getNotFoundImg()}  src={DWEnvironmentManager.getStoragePath() + 'assets' + product?.attributes?.image_big?.value} />,
            sapCode: product?.sapCode,
            serial: product?.serial,
            productDescription: getProductDescription(),
            creationStore: product?.creationStore,
            creationDate: product?.creationDate,
            expirationDate: product?.expirationDate,
            warrantyDuration: product?.warrantyDuration,
            manual: product?.attributes?.manual,
            brochure: product?.attributes?.brochure,
            matType: product?.attributes?.MatType?.value,
            extensible: product?.extensible,
            spPtDisclaimer: product?.spPtDisclaimer
        }
        /**
         * Ritorna il Component per visualizzare i dati di dettaglio della Landing Page
         * @hook landing_product_details
         * @param attributes: dati da mostrare
         * @param product prodotti
         */
        return applyFilters('landing_product_details', attributes, product);
    }

    return (
        <>
            {ready && !redirect && <Layout>
                <Content className='landing'>
                    <Card style={{ textAlign: 'center', marginTop: 100, marginBottom: 100 }} bordered={false}>
                        <Title level={4}>{__('landing.identified_product')}</Title>
                        <Title level={2}>{product?.attributes?.AestheticLine?.valueDescription}</Title>
                        <div style={{ marginBottom: 50, marginTop: 50}}>{attributes().image}</div>
                        <Typography>
                            <div style={{ marginTop: 20, backgroundColor: "#FAFAFA", paddingTop: 20 }}>
                                <Paragraph style={{ margin: 'auto' }}>{__b('landing.sapCode')} <strong>{attributes().sapCode}</strong></Paragraph>
                                <Paragraph>{__('landing.serial')} <strong>{attributes().serial}</strong></Paragraph>
                                <Row justify={"center"} style={{ marginTop: 30, marginBottom: 20 }}>
                                    <Col span={14} style={{ textAlign: "center"}}>
                                        <Paragraph>{attributes().productDescription}</Paragraph>
                                    </Col>
                                </Row>
                            </div>

                            <Paragraph><strong><i>{__('landing.information')}</i></strong></Paragraph>
                            <Paragraph style={{ margin: 'auto' }}>{__('landing.activation_store')}<strong> {attributes().creationStore} </strong></Paragraph>
                            <Paragraph>{__('landing.activation_date')}<strong> {(DateUtils.formatDate(attributes().creationDate))} </strong></Paragraph>
                            <Paragraph>{__b('landing.expiration_date')}<strong> {(DateUtils.formatDate(attributes().expirationDate))}</strong></Paragraph>
                            <Paragraph>{__b('landing.warranty_duration')}<strong> {attributes().warrantyDuration}</strong></Paragraph>
                            {product?.spPtDisclaimer && <Paragraph>{__b('landing.sp_pt_disclaimer')}</Paragraph>}
                        </Typography>
                        <Divider/>
                        <Link
                            onClick={() => navigate('/bu-info', {}, {state: {
                                uid: product?.uid,
                                language: getBrowserLanguage(),
                                matType: attributes().matType || 'ALL'
                            }})}>
                            <Image src={warranty_info} className='info' preview={false} width={50}/>
                        </Link>
                        <br /> <br />
                        <strong><a href={DWEnvironmentManager.getMainSite()} target="_blank" rel="noreferrer">{__b('app.url')}</a></strong>

                        {attributes().matType === 'PFMO' && <Row justify={"center"} style={{ marginTop: 30, marginBottom: 20 }}>
                            <Col span={12} style={{ textAlign: "center"}}>
                                <Divider>{__b('landing.download_manual')} </Divider>
                                <Link href={ `${ DWEnvironmentManager.getStoragePath () }assets/pdf/${ attributes().manual?.value }` }> {imgDownload}  </Link>
                            </Col>
                            <Col span={12} style={{ textAlign: "center"}}>
                                <Divider>{__b('landing.download_brochure')} </Divider>
                                <Link href={ `${ DWEnvironmentManager.getStoragePath () }assets/pdf/${ attributes().brochure?.value }` }> {imgDownload}  </Link>
                            </Col>
                        </Row>}
                    </Card>

                </Content>
            </Layout>}

            {ready && redirect && window.location.replace(DWEnvironmentManager.getMainSite())}
        </>
    )
}

export default LandingPage
